import { EmptyProps } from "@/definitions";
import { default as React } from "react";
import { FeaturesBlog, features } from "../../constans";

const Certificates: React.FC<EmptyProps> = () => {

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noreferrer")
  }

  return (
    <div className="relative mt-20 border-b border-color-navborder min-h-[800px] ">
      <hr />
      <div className="text-center">
        <h2 className="text-3xl sm:text-5xl lg:text-6xl text-left tracking-wide mt-10 mb-6 ml-6 lg:mt-20">
          My Certificates{" "}
        </h2>
      </div>
      <div>
        <div className="pt-8 ml-6">
          {(features as FeaturesBlog).map((feature, index) => (
            <div key={index}>
              <h2 className="text-lg md:text-2xl text-primary font-medium">{feature.type}</h2>
              <div className="flex flex-wrap w-full mb-14">
                {feature.contents.map((item, idx) => (
                  <button className="contents text-left" onClick={() => openInNewTab(item.link)}>
                    <a key={idx} href="#" className="transform transition duration-300 w-full sm:w-1/2 lg:w-1/3 px-6 py-4 my-3 mr-2 block max-w-sm rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                      <div className="relative">
                        <div className="absolute transform transition duration-300 hover:bg-blue-500/20 w-full h-36 z-10 rounded-lg"></div>
                        {
                          item.img ?
                            <img src={item.img} alt={item.img} className="w-full h-36 object-cover mb-4 rounded-lg" /> :
                            <p className="w-full h-36 pt-14 mb-4 bg-gray-200 dark:bg-gray-700 rounded-lg text-center text-gray-500 dark:text-white">No Preview</p>
                        }
                      </div>
                      <h5 className="mb-2 text-sm md:text-lg lg:text-lg font-medium tracking-tight text-gray-900 dark:text-white">{item.text}</h5>
                      <p className="text-sm pt-2 font-normal text-gray-700 dark:text-gray-400">{item.description}</p>
                    </a>
                  </button>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

    </div>
  );
};

export default Certificates;
