import { EmptyProps } from "@/definitions";
import React from "react";
import { IconContext } from "react-icons";
import { ChecklistItems, portfolioItems } from "../../constans";

const Portfolio: React.FC<EmptyProps> = () => {
  return (
    <div className="mt-20">
      <h2 className="text-3xl sm:text-5xl lg:text-6xl text-left mt-6 ml-6 tracking-wide">
        Portfolio Project{" "}
      </h2>
      <h2 className="text-md lg:text-xl text-left text-gray-700 dark:text-gray-300 mt-6 ml-6 tracking-wide">
        Things that I always trying to put my best.
      </h2>
      <p className="text-sm lg:text-xl text-left text-gray-700 dark:text-gray-400 mt-2 ml-6 tracking-wide">
        I've worked on many projects over the years, but these are some that I'm proud of.
      </p>
      <div className="flex flex-wrap w-full pt-12">
        {(portfolioItems as ChecklistItems).map((item, index) => (
          <div key={index} className="lg:w-1/2 md:w-full transform transition duration-300 flex mb-8 p-3 rounded-lg hover:bg-gray-100 dark:bg-gray-900 dark:border-gray-700 dark:hover:bg-gray-800">
            <div className="text-primary mx-4 bg-neutral-900 p-2 mt-1">
              <IconContext.Provider value={{ color: item.iconColor, size: '25px' }}>
                {item.icon}
              </IconContext.Provider>
            </div>
            <div>
              <h5 className="text-sm md:text-lg lg:text-xl mt-1 mb-2 font-semibold text-gray-900 dark:text-white">{item.app}</h5>
              <p className="text-sm  italic mt-1 mb-2 text-gray-500 dark:text-gray-400">{item.jobDesc}</p>

              <p className="text-sm text-neutral-500">{item.description}</p>
              <div className="mt-4">
                <div className="flex flex-wrap gap-2">
                  {portfolioItems[index].chip.map((itm, idx) => (
                    <div key={idx} className="text-xs relative grid select-none items-center whitespace-nowrap rounded-full py-1.5 px-3 font-sans font-bold bg-blue-900 text-blue-200 dark:text-blue-300">
                      {itm}
                    </div>
                  ))}
                </div>
              </div>

            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Portfolio;
